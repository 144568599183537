<script setup lang="ts">
import { getMenu } from "@/api/api";
import { useBookingDataStore } from "@/stores/bookingData";
import { onMounted, ref } from "vue";

const menus = ref(Array());

const bookingData = useBookingDataStore();

onMounted(async () => {
  const s = await getMenu(bookingData.date!);
  if (s?.success && s.content) {
    const thresholdDate = s.content
      .map((e) => {
        const d = new Date(e["Fin"]);
        d.setHours(12);
        return d;
      })
      .filter((f: Date) => f >= bookingData.date!)
      .reduce((prev, curr) => (prev < curr ? prev : curr));
    console.log("thresholdDate", thresholdDate);

    menus.value = s.content.filter((e) => {
      const d = new Date(e["Fin"]);
      d.setHours(12);
      return d.getTime() == thresholdDate.getTime();
    });
  }
});

function getMenuItemName(id: string) {
  return menus.value.find((f) => f.Id == id)?.Titre || "";
}

function getOrderedEntrees() {
  return bookingData.menu.filter((v) => v.id.includes("entree"));
}

function getOrderedPlats() {
  return bookingData.menu.filter((v) => v.id.includes("plat"));
}
</script>
<template>
  <div>
    <div class="bpa-front-module-heading">Résumé de vos choix</div>
    <div class="bpa-front-module-heading">Entrees</div>
    <div
      class="menu-summary"
      v-for="item in getOrderedEntrees()"
      :key="item.id"
    >
      {{ item.quantity }} x {{ getMenuItemName(item.id) }}
    </div>
    <br />
    <div class="bpa-front-module-heading">Plats</div>
    <div class="menu-summary" v-for="item in getOrderedPlats()" :key="item.id">
      {{ item.quantity }} x {{ getMenuItemName(item.id) }}
    </div>
    <br />
  </div>
</template>

<style scoped>
@media only screen and (max-width: 576px) {
  .menu-summary {
    margin: 0px 15px;
  }
}
</style>
