<script setup lang="ts">
const {
  priceLabel = "Prix",
  disabled = false,
  quantityLabel = "Quantité",
  editableQuantity = true,
  canDelete = true,
  canAdd = true,
  minValue = 1,
} = defineProps({
  title: String,
  description: String,
  pictureUrl: String,
  price: String,
  priceLabel: String,
  isSelected: Boolean,
  disabled: Boolean,
  quantityLabel: String,
  hasQuantity: Boolean,
  editableQuantity: Boolean,
  canDelete: Boolean,
  canAdd: Boolean,
  maxValue: Number,
  minValue: Number,
});

defineEmits(["click"]);

const model = defineModel<number>({ required: true });
</script>
<template>
  <div class="bpa-fm--si--col">
    <div class="bpa-front-module--service-item __bpa-is-description-enable">
      <div id="bap-service-7" class="bpa-front-si-card">
        <div class="bpa-front-si-card__row" @click="$emit('click')">
          <!---->
          <div class="bpa-front-si-card--checkmark-icon" v-if="isSelected">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29 5.7 12.7c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"
              />
            </svg>
          </div>
          <div class="bpa-front-si-card__left">
            <img
              :class="{ 'is-disabled': disabled }"
              :src="pictureUrl"
              alt=""
            />
          </div>
          <div
            class="bpa-front-si__card-body"
            :class="{ 'is-disabled': disabled }"
          >
            <div class="bpa-front-si__card-body--heading">{{ title }}</div>
            <p class="--bpa-is-desc">{{ description }}</p>
            <div v-if="price !== undefined" class="bpa-front-si-cb__specs">
              <!---->
              <div class="bpa-front-si-cb__specs-item">
                <p>
                  {{ priceLabel }} :
                  <strong
                    class="--is-service-price"
                    :class="{ 'is-disabled': disabled }"
                    >{{ price }}</strong
                  >
                </p>
              </div>
            </div>
            <br />
            <div v-if="hasQuantity && model > 0" class="bpa-front-si-cb__specs">
              <!---->
              <div class="bpa-front-si-cb__specs-item align-quantity">
                <quantity
                  :disabled="!editableQuantity"
                  :canDelete="canDelete"
                  :label="quantityLabel"
                  v-model="model"
                  :max="maxValue"
                  :min="minValue"
                ></quantity>
              </div>
            </div>
            <div
              v-if="hasQuantity && model == 0"
              class="bpa-front-si-cb__specs"
            >
              <!---->
              <div class="bpa-front-si-cb__specs-item align-quantity">
                <button v-if="canAdd"
                  type="button"
                  class="el-button bpa-front-btn bpa-front-btn__medium bpa-front-btn--primary el-button--default eauget-next-step"
                >
                  <!----><!----><span>
                    <strong>Ajouter&nbsp;</strong>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#5f6368"
                    >
                      <path
                        d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
                      /></svg
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---->
    </div>
  </div>
</template>

<style scoped>
img.is-disabled,
.--is-service-price.is-disabled {
  filter: grayscale();
}

@media only screen and (min-width: 576px) {
  .align-quantity {
    margin: auto !important;
    position: relative;
    left: -32px;
  }
}
</style>
